<style scoped>
</style>

<template>
  <div style="display: flex;flex-direction: row;">
    <PurchasePlan mode="exec" style="width: 250px; height: unset; flex: unset;" @item-choose="onChoosePlan" />
    <AppPage style="margin-left: 0; flex: 1; width: 0; height: unset;" @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
      <template v-slot:header>
        <Row type="flex" justify="space-between" :wrap="false" align="middle" style="margin-bottom: 10px;">
          <i-col style="flex: 1; width: 0;overflow-x: auto;white-space: nowrap;">
            <Row type="flex" align="middle">
              <i-col><fm-title title-text="采购入库"></fm-title></i-col>
              <i-col v-if="storehouseData"><Tag size="large" closable @on-close="$router.replace({ name: $route.name })">当前: {{ storehouseData.name }}</Tag></i-col>
            </Row>
          </i-col>
          <i-col>
            <Button v-if="$route.query.back" @click="$router.back()" style="margin-right: 10px;" icon="ios-arrow-back">返回</Button>
            <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          </i-col>
        </Row>
      </template>
      <template v-slot="{ contentHeight }">
        <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
          <template v-slot:goodsId="{ row }">
            {{ row.goodsName }}
          </template>
          <template v-slot:planVolume="{ row }">
            {{ row.planVolume !== null ? (row.planVolume + ' ' + row.goodsUnit) : '-' }}
          </template>
          <template v-slot:checkVolume="{ row }">
            {{ row.checkVolume !== null ? (row.checkVolume + ' ' + row.goodsUnit) : '-' }}
          </template>
          <template v-slot:dealVolume="{ row }">
            {{ row.checkVolume !== null ? (row.checkVolume + ' ' + row.goodsUnit) : '-' }}
          </template>
          <template v-slot:action="{ row }">
            <Button v-if="$authFunsProxy.manage" :loading="loading.submit" @click="itemDetail(row)" type="primary" size="small">明细</Button>
            <Button v-if="$authFunsProxy.manage" :loading="loading.submit" @click="itemClick(row)" style="margin-left: 10px;" type="primary" size="small">验货</Button>
          </template>
        </Table>
      </template>
      <template v-slot:footer>
        <Drawer placement="right" title="验货入库" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
          <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
            <InboundForm :storehouseId="currentStorehouseId" v-if="status.form" ref="form" />
          </DrawerAction>
        </Drawer>
        <Drawer placement="bottom" title="验货入库明细" :mask-closable="false" closable transfer draggable :height="80" v-model="status.detail">
          <InboundDetail @reload="loadData" accordingType="goods_purchase_detail" :accordingDataId="formData.id" v-if="status.detail" style="height: 100%; margin: 0;padding: 0;" />
        </Drawer>
      </template>
    </AppPage>
  </div>
</template>

<script>
import AppPage from '../../../components/page/base.vue'
import DrawerAction from '../../../components/drawer/action.vue'
import request from '../../../api'
import InboundForm from '../../../components/goods/purchase/form/inbound.vue'
import InboundDetail from './index.vue'
import PurchasePlan from '../../../components/goods/purchase/plan.vue'

export default {
  components: { AppPage, PurchasePlan, InboundForm, InboundDetail, DrawerAction },
  props: {},
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {},
      loading: {
        load: false,
        submit: false
      },
      dataList: [],
      status: {
        form: false,
        detail: false
      },
      formData: null,
      storehouseData: null,
      choosePlan: null
    }
  },
  computed: {
    currentStorehouseId () {
      const storehouseId = this.$route.query.storehouseId
      return storehouseId ? Number(storehouseId) : null
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    columns () {
      const columns = [
        { title: '物品', key: 'goodsId', slot: 'goodsId', fixed: 'left', width: 180 },
        { title: '规格型号', key: 'goodsGgxh', minWidth: 100 },
        { title: '计划量', key: 'planVolume', slot: 'planVolume', minWidth: 100 },
        { title: '入库量', key: 'dealVolume', slot: 'dealVolume', minWidth: 100 },
        { title: '备注', key: 'remark', ellipsis: true, tooltip: true, width: 160 },
        { title: '操作', key: 'action', slot: 'action', fixed: 'right', width: 140 }
      ]
      return columns
    }
  },
  watch: {
    currentStorehouseId () {
      if (this.routerName === this.$route.name) {
        this.loadStorehouseData()
      }
    }
  },
  methods: {
    async loadStorehouseData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      await this.$store.dispatch('loadStoreHouse')
      this.storehouseData = this.$store.getters.scmStoreHouseList.find(v => v.id === this.currentStorehouseId)
    },
    itemClick (item) {
      this.formData = item
      this.status.form = true
    },
    itemDetail (item) {
      this.formData = item
      this.status.detail = true
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (data === false) {
          return this.$Message.warning('请完整填写表单')
        }
        if (data === null) {
          return
        }

        this.loading.submit = true
        await request.post('/goods_purchase/detail/check_in/' + this.formData.id, data)
        this.$Message.success('操作成功')
        this.status.form = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    refresh () {
      this.pager.page = 1
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      if (!this.choosePlan) {
        return
      }

      const params = {
        exeStatus: '1',
        goodsPurchaseId: this.choosePlan.id,
        noGroup: 1
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_purchase/detail', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    },
    onChoosePlan (plan) {
      this.choosePlan = plan
      this.refresh()
    }
  },
  mounted () {
    this.loadStorehouseData()
  }
}
</script>
